import {
  Button,
  DatePicker,
  DatePickerProps,
  Input,
  Space,
  Table,
  Typography,
} from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import tagIcon from "../../assets/tagIcon.png";

import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { theme } from "antd";

const Accounting = () => {
  const navigate = useNavigate();
  const now = dayjs();
  const { RangePicker } = DatePicker;
  const moment = require("moment");
  const currentDate = moment();
  // const defaultDate = currentDate.subtract(1, "month").format("YYYY-MM");

  const [page, setPage] = useState(1);

  const { token } = theme.useToken();

  const [date, setDate] = useState<string | null>(null);

  const disabledDate = (current: any) => {
    return current && current >= moment().add(1, "month").startOf("month");
  };

  const onChangeDate: DatePickerProps["onChange"] = (date) => {
    if (!date) {
      setDate(null);
    } else {
      const firstDate = date.startOf("month").format("YYYY-MM");
      setDate(firstDate);
    }
  };

  interface SalaryData {
    id: number;
    salary: number;
    date: string;
    task: string;
    total_salary: number;
    base_salary: number;
    performance_salary: number;
    user: {
      id: number; // user.id mavjud bo'lishi kerak
      name: string; // boshqa foydalanuvchi ma'lumotlari
    };
  }

  const [salaries, setSalaries] = useState<SalaryData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  useEffect(() => {
    if (!date) return;
    const fetchSalaries = async () => {
      const API_URL = "https://api.tteld.co/api/v1/user-salaries/";
      const AUTH_TOKEN = localStorage.getItem("access");

      try {
        const response = await axios.get(API_URL, {
          params: {
            // page: page,
            page_size: 999,
            date: date,
            // salary_type: "task_based",
          },
          headers: {
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        });

        console.log(response.data.data);

        setSalaries(response.data.data);
        setLoading(false);
      } catch (error: any) {
        setError(error.message || "Something went wrong");
        setLoading(false);
      }
    };

    fetchSalaries();
  }, [date]);

  return (
    <div>
      <div
        className="header d-flex  statistics-header"
        style={{ marginBottom: 16 }}
      >
        <Typography className="title">Accounting</Typography>
        <div>
          <DatePicker
            onChange={onChangeDate}
            picker="month"
            format={"MMMM YYYY"}
            disabledDate={disabledDate}
            // defaultValue={onChangeDatePicker}
            style={{ marginRight: 10, width: 150, marginBottom: 10 }}
          />
        </div>
      </div>

      <Table
        size="small"
        dataSource={salaries?.map((u, i) => ({
          no: i + 1,
          ...u,
        }))}
        columns={[
          {
            title: <img src={tagIcon} alt="" />,
            dataIndex: "no",
            key: "no",
            width: "5%",
          },
          {
            title: "Username",
            dataIndex: ["user", "username"], // Ichki obyektga yo‘l
            key: "username",
          },
          {
            title: "Salary Type",
            dataIndex: "salary_type",
            render: (value: any, record: any) => {
              if (record.salary_type === "task_based") {
                return <p>Task Based</p>;
              } else if (record.salary_type === "hybrid") {
                return <p>Hybrid</p>;
              } else {
                return <p>{record.salary_type}</p>; // Agar boshqa qiymat bo'lsa, oddiy qilib chiqariladi
              }
            },
            filters: [
              {
                text: "Hybrid",
                value: "hybrid",
              },
              {
                text: "Task Based",
                value: "task_based",
              },
            ],
            filterMultiple: false,
            // defaultFilteredValue: ["hybrid"],
            onFilter: (value: any, record: any) => {
              return record.salary_type === value;
            },
          },
          {
            title: "Base Salary",
            dataIndex: "base_salary",
            render(value, record, index) {
              return <p>${record?.base_salary}</p>;
            },
          },
          {
            title: "Performance Salary",
            dataIndex: "performance_salary",
            render(value, record, index) {
              return <p>${record?.performance_salary}</p>;
            },
          },
          {
            title: "Total Salary",
            dataIndex: "total_salary",
            render(value, record, index) {
              return <p>${record?.total_salary}</p>;
            },
          },
        ]}
        rowClassName={(record, index) =>
          index % 2 === 0 ? "odd-row" : "even-row"
        }
        bordered
        pagination={{
          pageSize: 10,
          size: "default",
          style: {
            margin: 0,
            justifyContent: "end",
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            backgroundColor: token.colorBgContainer,
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.4)",
            padding: "10px 0",
            zIndex: 1000,
          },
          showLessItems: true,
        }}
        onRow={(record) => ({
          onClick: () => {
            if (record.user && record.user.id) {
              navigate(`/accounting/${record.user.id}`); // `user.id`ni olish
            } else {
              console.error("User ID mavjud emas");
            }
          },
        })}
      />
      {/* <Space style={{ width: "100%", marginTop: 10 }} direction="vertical">
        <Space
          style={{
            justifyContent: "end",
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            backgroundColor: token.colorBgContainer,
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.4)",
            padding: "10px 0",
            zIndex: 1000,
          }}
          wrap
        >
          <Button
            onClick={Previos}
            disabled={salaries?.previous ? false : true}
            style={{
              backgroundColor: token.colorBgContainer,
              color: token.colorText,
              border: "none",
            }}
          >
            <LeftOutlined />
          </Button>

          <Input
            disabled
            style={{
              width: 40,
              textAlign: "center",
              background: token.colorBgContainer,
              border: "1px solid",
              borderColor: token.colorText,
              color: token.colorText,
            }}
            value={page}
            onChange={(e) => {
              let num = e.target.value;
              if (Number(num) && num !== "0") {
                setPage(Number(num));
              }
            }}
          />

          <Button
            onClick={Next}
            disabled={data?.next ? false : true}
            style={{
              backgroundColor: token.colorBgContainer,
              color: token.colorText,
              border: "none",
            }}
          >
            <RightOutlined />
          </Button>
      
        </Space>
      </Space> */}
    </div>
  );
};

export default Accounting;
